@font-face {
    font-family: 'Cairo';
    src: url('https://objectstorage.me-jeddah-1.oraclecloud.com/n/axci4wuwpelt/b/widget_static_dev/o/Cairo-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cairo';
    src: url('https://objectstorage.me-jeddah-1.oraclecloud.com/n/axci4wuwpelt/b/widget_static_dev/o/Cairo-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
