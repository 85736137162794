@import "./fonts/Cairo/cairo.css";

@font-face {
  font-family: 'Brother-1816';
  src: url('https://objectstorage.me-jeddah-1.oraclecloud.com/n/axci4wuwpelt/b/widget_static_dev/o/Brother1816Printed-Book.woff') format('woff');
  font-style: normal;
}

.quara-widget {
  font-family: Brother-1816, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

